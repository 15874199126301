import React, { useEffect, useState } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import axios from "axios";
import { Field, reduxForm } from "redux-form";
import { validate } from "../../../services";
import { Grid, Typography } from "@material-ui/core";
import { renderSelectField } from "../../../components/Fields/Fields";
import BasicForm from "../../../components/BasicForm";
import { isMobile } from "react-device-detect";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    padding: "4px",
  },
}));

const formName = "MapaVisitas";
const modelName = "mapavisitas";
const label = "Mapa Visitas";

const MyMapComponent = withScriptjs(
  withGoogleMap(({ locations }) => {
    const classes = useStyles();
    const getIconUrl = (tipo_voto) => {
      switch (tipo_voto) {
        case "N":
          return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
        case "I":
          return "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
        case "S":
          return "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
        case "D":
          return "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
        default:
          return "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
      }
    };

    return (
      <GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat: -7.111588, lng: -34.850261 }}
        options={{ streetViewControl: false }}
      >
        {locations.map((location, index) => (
          <Marker
            key={index}
            position={{
              lat: parseFloat(location.lat),
              lng: parseFloat(location.lng),
            }}
            icon={{
              url: getIconUrl(location.tipo_voto),
              scaledSize: new window.google.maps.Size(32, 32),
            }}
          />
        ))}
      </GoogleMap>
    );
  })
);

let Form = (props) => {
  const [district, setDistrict] = useState("all");
  const [voteType, setVoteType] = useState("all");
  const [locations, setLocations] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [voteCounts, setVoteCounts] = useState({ N: 0, I: 0, S: 0, D: 0 });

  useEffect(() => {
    filterLocationsList();
  }, [voteType, district]);

  const renderSelection = (list, isVoteType = false) => {
    if (list && list.length > 0) {
      const uniqueEntities = new Set();
      const finalList = [
        <option value={"all"} key={"all"}>
          Todos
        </option>,
      ];

      list.forEach((entity) => {
        const displayName = isVoteType
          ? getVoteTypeLabel(entity.tipo_voto)
          : entity.bairro;

        if (displayName && !uniqueEntities.has(displayName)) {
          uniqueEntities.add(displayName);
          finalList.push(
            <option key={entity.id} value={entity.tipo_voto || entity.bairro}>
              {displayName}
            </option>
          );
        }
      });

      return finalList;
    }
  };

  const getVoteTypeLabel = (tipo_voto) => {
    switch (tipo_voto) {
      case "N":
        return "Contras";
      case "I":
        return "Indecisos";
      case "S":
        return "Aliados";
      case "D":
        return "Dia";
      default:
        return "Indecisos";
    }
  };

  const changeDistrict = async (e) => {
    if (e.target.value) setDistrict(e.target.value);
    filterLocationsList();
  };

  const changeVoteType = (e) => {
    if (e.target.value) setVoteType(e.target.value);
    filterLocationsList();
  };

  function filterLocationsList() {
    const filteredList = locations.filter((location) => {
      if (district === "all" && voteType === "all") {
        return true;
      } else if (district !== "all" && voteType === "all") {
        return location.bairro === district;
      } else if (district === "all" && voteType !== "all") {
        return location.tipo_voto === voteType;
      } else {
        return location.bairro === district && location.tipo_voto === voteType;
      }
    });
    setFilteredList(filteredList);

    const counts = filteredList.reduce(
      (acc, location) => {
        acc[location.tipo_voto] = (acc[location.tipo_voto] || 0) + 1;
        return acc;
      },
      { N: 0, I: 0, S: 0, D: 0 }
    );
    setVoteCounts(counts);
  }

  useEffect(() => {
    const fetchLocations = async () => {
      const token = sessionStorage.getItem("token");

      if (!token) {
        console.error("Token não encontrado");
        return;
      }

      try {
        const response = await axios.get(
          "https://conectacorujinha.com.br/api-fp/pesquisa/mapa",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const locationsWithFloatCoords = response.data.map((location) => ({
          ...location,
          bairro: location.bairro,
          lat: parseFloat(location.lat),
          lng: parseFloat(location.lng),
          tipo_voto: location.tipo_voto,
        }));
        const districtsList = response.data.map((location) => ({
          bairro: location.bairro,
        }));

        setLocations(locationsWithFloatCoords);
        setDistricts(districtsList);
        setFilteredList(locationsWithFloatCoords);

        // Update vote counts on initial load
        const initialCounts = locationsWithFloatCoords.reduce(
          (acc, location) => {
            acc[location.tipo_voto] = (acc[location.tipo_voto] || 0) + 1;
            return acc;
          },
          { N: 0, I: 0, S: 0, D: 0 }
        );
        setVoteCounts(initialCounts);
      } catch (error) {
        console.error("Erro ao buscar localizações:", error);
      }
    };

    fetchLocations();
  }, []);

  useEffect(() => {
    console.log("Locations updated:", locations);
  }, [locations]);

  return (
    <BasicForm
      keepUpdateAfterSubmit={true}
      hiddenSaveButton={true}
      hiddenBackButton={false}
      content={
        <Grid container spacing={3} wrap="wrap">
          <Grid item xs={isMobile ? 12 : 4}>
            <Field
              name="bairro"
              initialValues={"all"}
              component={renderSelectField}
              onChange={changeDistrict}
              label="Bairros"
              style={{ maxWidth: "100%", width: 350 }}
            >
              {renderSelection(districts)}
            </Field>
          </Grid>

          <Grid item xs={isMobile ? 12 : 4}>
            <Field
              name="tipoVoto"
              component={renderSelectField}
              label="Grupo Político"
              onChange={changeVoteType}
              style={{ maxWidth: "100%", width: 350 }}
            >
              {renderSelection(locations, true)}
            </Field>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Typography
              variant="h6"
              style={{
                backgroundColor: "#1515abff",
                color: "white",
                width: 200,
                padding: 10,
                textAlign: "center", // alinha o texto ao centro
                flexDirection: "row",
                borderRadius: 5,
              }}
            >
              Quantidade de Votantes: {filteredList.length}
            </Typography>
            <Typography
              variant="body1"
              style={{
                backgroundColor: "#fd7567",
                color: "black",
                fontWeight: "bold",
                width: 100,
                padding: 10,
                textAlign: "center", // alinha o texto ao centro
                flexDirection: "row",
                borderRadius: 5,
              }}
            >
              Contras: {voteCounts.N}
            </Typography>
            <Typography
              variant="body1"
              style={{
                backgroundColor: "#fdf569",
                color: "black",
                fontWeight: "bold",
                width: 100,
                padding: 10,
                textAlign: "center", // alinha o texto ao centro
                flexDirection: "row",
                borderRadius: 5,
              }}
            >
              Indecisos: {voteCounts.I}
            </Typography>
            <Typography
              variant="body1"
              style={{
                backgroundColor: "#05df4b",
                color: "black",
                fontWeight: "bold",
                width: 100,
                padding: 10,
                textAlign: "center", // alinha o texto ao centro
                flexDirection: "row",
                borderRadius: 5,
              }}
            >
              Dia: {voteCounts.D}
            </Typography>
            <Typography
              variant="body1"
              style={{
                backgroundColor: "#6991fd",
                color: "black",
                fontWeight: "bold",
                width: 100,
                padding: 10,
                textAlign: "center", // alinha o texto ao centro
                flexDirection: "row",
                borderRadius: 5,
              }}
            >
              Aliados: {voteCounts.S}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <div>
              <MyMapComponent
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCew-1aoCFSGHSWYMspFjrkkvXr_j7z3rA&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: "100%" }} />}
                containerElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "600px" }} />}
                locations={filteredList}
              />
            </div>
          </Grid>
        </Grid>
      }
      formName={formName}
      modelName={modelName}
      label={label}
      {...props}
    />
  );
};

Form = reduxForm({
  form: formName,
  validate,
  initialValues: {
    district: "all",
    voteType: "all",
  },
})(Form);

export default Form;
