import Dexie from 'dexie';
import { v4 as uuidv4 } from 'uuid';
import api from './services/api';

// import WebSocketSyncProtocol from './WebSocketSyncProtocol';

export const db = new Dexie('fator_politico_db');

//Esquematizar o banco
db.version(1).stores({
  // user:'$$uid, name, cpf, address, number, neighborhood, city, state, phone, birthDate, password, confirmed',
  // pesquisa: '$$uid, vote, hierarquia_familiar, user_id, responsavel_id',
  // pesquisaImage: '$$uid, image, pesquisa_id, created_at, updated_at'
  state: '&id,value',
  data: '$$uid, name, cpf, address, number, neighborhood, city, state, phone, birthDate, password, vote, hierarquia_familiar, ownerId, tipo_voto,photo, created_at, updated_at'
});


db.open().catch((err) => {
  console.error('Failed to open db: ' + err.stack);
});


const addPesquisa = async (body) => {
  try {
      const { tipo_voto, photo, name, cpf, address, number, neighborhood, city, state, phone, birthDate, vote, hierarquia_familiar, ownerId } = body;

      if (!name || !cpf || !address || !number || !neighborhood || !city || !state || !phone || !birthDate || !vote || !hierarquia_familiar || !ownerId || !tipo_voto) {
          throw new Error('Missing required fields');
      }

      const uuid = uuidv4();
      console.log('Adding data to Dexie:', { uuid, photo, name, cpf, address, number, neighborhood, city, state, phone, birthDate, vote, hierarquia_familiar, ownerId });
      await db.data.add({
          $$uid: uuid, tipo_voto, photo, name, cpf, address, number, neighborhood, city, state, phone, birthDate, vote, hierarquia_familiar, ownerId, created_at: new Date(), updated_at: new Date()
      });

      console.log('Data added successfully');
  } catch (err) {
      console.error('Error adding pesquisa:', err);
      throw err;  // Rethrow the error to handle it in the calling function
  }
};

// const updateTable = async (id, updates) => {
//   try {
//     // const updated = await db[table].update(id, updates);
//     const updated = await db.data.update(id, updates);
    
//     if (updated) {
//       setSyncState('0')
//     }
//   } catch (err) {
//     console.error('Error updating friend: ' + err.stack);
//   }
// };

const deleteFromTable = async (id) => {
  try {
    // await db[table].delete(id);
    await db.data.delete(id);
    
    setSyncState('0')
  } catch (err) {
    console.error('Error deleting: ' + err.stack);
  }
};

const getTableToArray = async () => {
  try {
    const data = await db.data.toArray();
    return data;
  } catch (err) {
    console.error(`Error fetching data from ${db.data}: ` + err.stack);
    return [];
  }
};


  const sendDataToEndpoint = async (endpoint, data) => {
    try {
      const response = await api.post(endpoint, data);
      
      if (response.status === 409) {
        // ERRO cpf existente
        console.error('CPF já existe');
        return { error: 'CPF já existe' };
      }
      
      if (response.status !== 201) {
        console.error('Network response was not ok', response);
        throw new Error('Network response was not ok');
      }
      
      const responseData = response.data;
      console.log('Data sent successfully:', responseData);
      return responseData;
    } catch (e) {
      console.error('Error sending data to endpoint:', e);
      return { error: e.message };
    }
  };
  

const syncData = async () => {
  if (navigator.onLine) {
    try {
      const data = await getTableToArray();
      if (data.length > 0){
        for (const item of data){
          const responseData = await sendDataToEndpoint('https://conectacorujinha.com.br/api-fp/pesquisa', item);

          console.log(responseData);

          if (responseData.success) {
            await db.data.deleteFromTable(item.$$uid);
          }
        }
      }
      setSyncState(1);

    } catch (err) {
      console.error('Error syncing data:', err);
    }
  }
};


const setSyncState = async (state) => {
  try {
    // Atualiza ou adiciona o estado de sincronização
    await db.state.put({ id: 'syncState', value: state });
  } catch (err) {
    console.error('Error updating sync state: ' + err.stack);
  }
};

const getSyncState = async () => {
  try {
    const state = await db.state.get('syncState');
    if (state) {
      return state.value;
    }
    return 0
  } catch (err) {
    console.error('Error retrieving sync state: ' + err.stack);
    return null;
  }
};


export {
  addPesquisa,
  // updateTable,
  deleteFromTable,
  // registerSync,
  sendDataToEndpoint,
  getTableToArray,
  getSyncState,
  syncData
};

// export default db;

