import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardHeader, Divider, Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import api from "../../../../services/api";
import RouteButton from "../../../../components/RouteButton";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Assessor = (props) => {
  const { className, ...rest } = props;
  const profile = props.profile;
  const classes = useStyles();
  const history = useHistory();

  const [bairroList, setBairroList] = React.useState([]);

  React.useEffect(() => {
    getBairroList();
  }, []);

  const getBairroList = async () => {
    try {
      // Tente obter os dados do cache primeiro
      const cache = await caches.open("bairro-cache");
      const cachedResponse = await cache.match("bairro-list");

      if (cachedResponse) {
        const data = await cachedResponse.json();
        setBairroList(data);
        console.log("Fetched bairro list from cache:", data);
      } else {
        // Se não houver dados no cache, faça a chamada à API
        const response = await api.get("/bairro?pageSize=-1");
        const data = response.data.rows;
        setBairroList(data);
        console.log("Fetched bairro list from API:", data);

        // Salve os dados no cache
        const responseToCache = new Response(JSON.stringify(data));
        await cache.put("bairro-list", responseToCache);
      }
    } catch (error) {
      console.error("Failed to fetch or cache bairro list:", error);
    }
  };

  const handleVisit = (e) => {
    history.push(`/registroVisita`);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form>
        <CardHeader title={"Menu do " + profile} />
        <Grid container spacing={3} wrap="wrap">
          <RouteButton
            title={"Adiciona Visita"}
            iconName={"condominio"}
            iconSize={36}
            fill={"#fff"}
            bgColor={"#3332e4"}
            handlerAction={handleVisit}
          />
        </Grid>

        <Divider style={{ marginTop: 50 }} />
      </form>
    </Card>
  );
};

Assessor.propTypes = {
  className: PropTypes.string,
};

export default Assessor;
